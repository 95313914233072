import "./RouteErrorPage.css"

const RouteErrorPage = () => {
    return (
        <div>
            This is an error page.
        </div>
    )
}

export default RouteErrorPage;